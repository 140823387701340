export const DASHBOARD_ROUTE = 'dashboard'
export const CUSTOMERS_ROUTE = 'customers'
export const CATEGORIES_ROUTE = 'categories'
export const DELIVERIES_ROUTE = 'deliveries'
export const MANUFACTURERS_ROUTE = 'manufacturers'
export const PRODUCTS_ROUTE = 'products'
export const PURCHASES_ROUTE = 'purchases'
export const STORES_ROUTE = 'stores'
export const LOGOUT_ROUTE = 'logout'
export const PROFILE_ROUTE = 'profile'
export const USERS_ROUTE = 'users'