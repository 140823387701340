import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Header from "./Header";
import Copyright from "./Copyright";
import LoadDelay from "../LoadDelay/LoadDelay";

export default function Layout() {
    return (
        <Box sx={{ display: 'flex' }}>
            <Header />
            <Box
                component="main"
                sx={{
                    backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                            ? theme.palette.grey[100]
                            : theme.palette.grey[900],
                    flexGrow: 1,
                    height: '100vh',
                    overflow: 'auto',
                }}
            >
                <Toolbar />
                <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>

                    <Outlet />

                    <Copyright sx={{ pt: 4 }} />
                </Container>
            </Box>
            <LoadDelay />
        </Box>
    );
}